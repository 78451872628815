@import url(https://fonts.googleapis.com/css2?family=Mulish&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Muli", sans-serif;
}

.navbar {
  background-color: #fff !important;

}

.navbar-brand {
  font-size: 1.9rem !important;
  color: #565387 !important;
}

.navbar a {
  font-size: 1.3rem;
  text-transform: capitalize;
}

.menu_active {
  font-weight: bold;
  border-bottom: 1px solid #565387;
}

.navbar a:hover {
  color: #3498db !important;

}

@media (max-width: 991px) {
  .navbar-brand {
    font-size: 1.5rem !important;
  }
  .menu_active {
    font-weight: bold;
    border-bottom: none;
  }
}

/*MAIN LANDING SECTION */

#header {
  width: 100%;
  height: 80vh;
  margin-top: 70px;
}

#header h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  color: black;
}

#header .brand-name {
  color: #3498db;
}
#header h2 {
  color: #484848;
  font-size: 24px;
}

#header .btn-get-started {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #3498db;
  border: 2px solid #3498db;
  text-decoration: none;
}

#header .btn-get-started:hover {
  background: #3498db;
  color: #fff;
}

.header-img {
  text-align: right;
}

#header .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@keyframes up-down {
  0% {
    transform: translateY(10px);

  }
  100% {
    transform: translateY(-10px);
  }
}

